export class BaseModel {
  public id: number = null;
  public cacheKey: string = null;
  public etag: string = null;

  protected cache: Record<string, unknown> = {};

  public toString(): string {
    return null;
  }

  protected setProps<T>(args: Partial<T>, omit: string[] = []): void {
    Object.entries(args).forEach(([key, value]) => {
      if (!Object.prototype.hasOwnProperty.call(this, key) || omit.includes(key)) {
        return;
      }

      Reflect.set(this, key, value);
    });
  }
}
